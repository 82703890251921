<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%;")
      div(style="width: 290px; display: flex; align-items: center;")
        Card_1_SVG_1
      div.d-flex.flex-column.justify-start(style="width: 100%")
        div
          p(style="color: #F93549; font-weight: 600; margin-bottom: 0.8em;") QUÉ ES
          p(style="font-size: 14px; margin-bottom: 0.8em;") Cada marca o restaurante crea sus propias recetas que por lo general son distintas de las de la competencia. Esas diferencias en la composición del plato hace que los productos no sean exactamente iguales y por tanto permite cierta libertad de precios ya que los clientes entienden que no son 100% comparables.
          p(style="font-size: 14px; margin-bottom: 0.8em;") Sin embargo hay una serie de productos en la oferta de los restaurantes que se pueden denominar comodities, como por ejemplo agua, refrescos, cañas, cafés, etc…  y que son comparables en sus precios por los clientes, hasta el punto que cuando el precio de algún producto comoditie es considerado alto / caro por los clientes, éstos pueden llegar a pensar que el resto de la oferta esta también mas cara que en la competencia.
          p(style="font-size: 14px;") EL IPB es un indicador que nos permite medir #[strong y comparar la cesta de comodities de la marca respecto a la competencia] a fin de no incurrir en marcajes de precios o demasiado altos o demasiado bajos en productos que son idénticos y comparables.


  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%;")
      div(style="width: 290px; display: flex; align-items: center;")
        Card_1_SVG_1_en
      div.d-flex.flex-column.justify-start(style="width: 100%")
        div
          p(style="color: #F93549; font-weight: 600; margin-bottom: 0.8em;") WHAT DOES IT MEAN
          p(style="font-size: 14px; margin-bottom: 2em;") Each brand or restaurant creates its recipes that are generally different from those of the competition. These differences in the recipes or composition of the dish mean that the products are not exactly the same and therefore allows some freedom of prices since customers understand that they are not 100% comparable.
          p(style="font-size: 14px; margin-bottom: 2em;") However, there are a series of products in all restaurants that can be called commodities, such as water, soft drinks, beers, coffees, etc., and they are comparable in their prices by customers.  So when the price of some commodity product is considered high/expensive by customers, they may think that the rest of the offer is also at higher prices than the competition.
          p(style="font-size: 14px;") The CPI is an indicator that allows us to measure and compare the brand's basket of commodities against the competition not to incur price markings or too high or too low in identical and comparable products.

</template>

<script>
import Card_1_SVG_1 from "./assets/Card_1_SVG_1";
import Card_1_SVG_1_en from "./assets/Card_1_SVG_1_en";

export default {
  name: "Card_1",
  components: {Card_1_SVG_1_en, Card_1_SVG_1}
}
</script>

<style scoped>

</style>
